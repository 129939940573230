<template>
    <EstructuraBasica class="mt-n15">
        <template v-slot:main>
            <ToolbarMultiIcons 
                            :titulo="'Reportes de digitalización'" 
                            
                            
                            @generate_pdf="generate_pdf()"
                            :actions="[
                                        
                                        {value: 'generate_pdf',icon: 'mdi-file-document-multiple-outline',name: 'Generar PDF'}
                                        ]">
                <template v-slot:accion_retroceder>
                    <v-btn fab small text @click="$router.go(-1)">
                        <v-icon> mdi-chevron-left </v-icon>
                    </v-btn>
                </template>
                <template v-slot:button_custom>
                    <vue-excel-xlsx
                        :data="desserts"
                        :columns="columns"
                        :filename="title_report"
                        :sheetname="'Hoja 1'" 

                        
                        >
                        <v-btn class="mt-1" block rounded="" dark color="white" style="color:blue" >
                            <span>
                                Descargar
                            </span>
                            <v-icon>
                                mdi-microsoft-excel
                            </v-icon>
                        </v-btn>
                    </vue-excel-xlsx> 
                </template>
                </ToolbarMultiIcons>
            <v-sheet
                    color="grey lighten-4"
                    elevation=""
                    rounded=""
                    class="mt-3 mx-auto"
                    min-height="500"
                    max-width="1000"
                >
                    <v-row>
                        <Load class="mx-5" :load="load" />
                    </v-row>
                    <v-row
                    align="center"
                    justify="center"
                    >
                    <v-col>
                        <v-row>
                            <v-col cols="12" sm="10">
                                <v-row>
                                <v-col>
                                    <DatePicker @fecha="reporte.fecha_inicial=$event" :label="'Fecha de inicio'" />
                                </v-col>
                                <v-col>
                                    <DatePicker  @fecha="reporte.fecha_final=$event" :label="'Fecha de finalizacion'" />
                                </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="8" sm="1">
                                <v-row class="mt-5">
                                    <v-btn 
                                        @click="realizar_reporte(reporte)"
                                        :disabled="validacion" style="color:white" block color="red darken-4"> 
                                        <v-icon>mdi-file-pdf-outline</v-icon> 
                                        Reporte 
                                    </v-btn>
                                </v-row>
                            </v-col>  
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-data-table
                                    :headers="headers"
                                    :items="desserts"
                                    class="elevation-1"
                                >
                                </v-data-table>
                            </v-col>
                        </v-row>

                    </v-col>
                    
                    
                </v-row>
                    
            </v-sheet>
        </template>
    </EstructuraBasica>
</template>
<script>

import DatePicker from "../../../components/celulas/DatePicker.vue"
import ToolbarMultiIcons from "../../../components/atomos/ToolbarMultiIcons.vue";
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from 'vue'
Vue.use(VueExcelXlsx)
export default {
  components: {
        DatePicker,
        ToolbarMultiIcons
    },
    data(){
        return {
            columns: 
            [
                    { label: 'Tipo documental', field: 'tipo_documental'},
                    { label: 'Cantidad', field: 'Pendiente' },
            ],
            headers: [
                {
                    text: 'Tipo documental',
                    align: 'start',
                    value: 'tipo_documental',
                },
                { text: 'Cantidad imagenes', value: 'cantidad' },
            ],
            desserts: [],
            tipos_documentales: [],

            reporte: {
                fecha_inicial:new Date().toISOString().substr(0, 10) ,
                fecha_final: new Date().toISOString().substr(0, 10) ,
                tipo_documental: null
            },
            title_report: "",
        }
    },
    created(){
        this.consultar_tipos_documentales();
        


    },
    methods: {
        async realizar_reporte(){


            try {
              console.log('Do report');
               console.log(this.tipos_documentales);
              for (let index = 0; index < this.tipos_documentales.length; index++) {
                
                try {
                    //https://docutest.educandote.co/types/collections/IMAGENES_${this.tipos_documentales[index].nombre_endpoint_almacenamiento}/documents/search?q=habilitada&query_by=estado_imagen&per_page=1
                    

                    //ciclo que recorra rangos de fechas anuales ( 2000 - 2001 ) hasta el 2020 en milisegundos que traiga las OP de esasa fechas 
                    //fecha_documento_int
                    
                    let response = await this.$store.dispatch('llamado_get',{
                        url: `https://docutest.educandote.co/types/collections/IMAGENES_${this.tipos_documentales[index].nombre_endpoint_almacenamiento}/documents/search?q=Plataforma antigua&query_by=autor&group_by=id_busqueda&per_page=250`,
                        tipo_header: "indexador"
                    })

                    console.log(response);
                    this.desserts.push({
                        tipo_documental: this.tipos_documentales[index].nombre,
                        cantidad: response.data.found
                    });


                } catch (error) {
                    console.log(error);
                }
                    
              }
            } catch (error) {
                console.log(error);
            }
        },
        async generate_pdf(){
            try {
                var doc = new jsPDF('p', 'pt');
                doc.setFont("times", "normal");
                doc.setFontSize(15);
                console.log('x')
                
                this.title_report=`Reporte ${this.reporte.fecha_inicial}  ${this.reporte.fecha_final}`;
                console.log(this.title_report)
                doc.text(this.title_report, 200, 25);

                //doc.text(' '+this.information.servicerequest.extendedproperties.ips_remisora.value, 135, 65);
                //doc.addImage('../../../assets/buscar.png',"", 50, 30, 60, 70) 
                //doc.addImage(require('../../../assets/img2.png'), "JPG", 410, 30, 120, 90)
                //doc.addImage(require('./assets/logo_clinica_2.jpg'), "JPG", 410, 35, 120, 90) //410,35,140,30 para sm
            
                
                var columns_info_general = [
                    {title: "Tipo documental", dataKey: "tipo_documental"},
                    {title: "Cantidad de imagenes", dataKey: "cantidad"},
                        //{title: "nota", dataKey: "nota"}
                    ];
                    let info_general = [...this.desserts];

                    console.log(this.desserts);
                
                    doc.autoTable(columns_info_general, info_general, {
                        margin: {top: 20},
                        pageBreak: 'auto',
                        startY: 50
                    });

                const pageCount = doc.internal.getNumberOfPages();

                // For each page, print the page number and the total pages
                doc.setFontSize(10)
                

                for(let i = 1; i <= pageCount; i++) {
                    // Go to page i
                    doc.setPage(i);
                    //Print Page 1 of 4 for example
                    doc.text('DOC Pagina ' + String(i) + ' de ' + String(pageCount),570,820,null,null,"right");
                }
                doc.save(this.title_report+'.pdf');
                //Almacenar firma de quien lo descarga
            } catch (error) {
                console.log(error);
            }
        },
        async consultar_tipos_documentales(){
            try {
            let tipos_documentales = await this.$store.dispatch('llamado_get',{
                url: "https://audidoc.educandote.co/parse/classes/V1_tiposDocumentales",
                tipo_header: "parse"
                })
                console.log(tipos_documentales)
                this.tipos_documentales = [...tipos_documentales.data.results]
            } catch (error) {
                console.log(error)
            }
        },
    },
}
</script>